@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap);
.shards-landing-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url("/imagesHome/agency-landing/welcome-cover.jpg") no-repeat
    center center fixed;
  background-size: cover;
}
img.big-logo{
  
   max-width:85%;
}
.shards-landing-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background: #007bff;
}
@media (max-width: 767.98px) {
  .shards-landing-page--1 .welcome .inner-wrapper {
    text-align: center;
  }
}
@media (max-width: 400px) {
  .shards-landing-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}
.shards-landing-page--1 .section-title {
  position: relative;
}
.shards-landing-page--1 .section-title:after {
  content: "";
  width: 30px;
  height: 2px;
  background: #007bff;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: -20px;
}
.shards-landing-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}
.shards-landing-page--1 .section {
  border-bottom: 1px solid #eaebed;
}
.shards-landing-page--1 .section-invert {
  background: #f9fafc;
}
.shards-landing-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}
.shards-app-promo-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url(/imagesHome/app-promo/welcome-cover.jpg) no-repeat center
    center fixed;
  background-size: cover;
  margin-bottom: -1px;
}
.shards-app-promo-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  background: #212529;
}
@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .welcome {
    min-height: auto;
  }
  .shards-app-promo-page--1 .welcome .header-social-icons {
    display: none;
  }
}
@media (max-width: 400px) {
  .shards-app-promo-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}
.shards-app-promo-page--1 .welcome .iphone-mockup {
  max-width: 85%;
}
.shards-app-promo-page--1 .section-title.underline--left:after {
  left: 0;
  margin-left: 0;
}
.shards-app-promo-page--1 .app-screenshot {
  background: url(/imagesHome/app-promo/features-background.jpg) no-repeat
    center center fixed;
  background-size: cover;
}
.shards-app-promo-page--1 .app-screenshot:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e9ecef;
  opacity: 0.6;
}
.shards-app-promo-page--1 .app-screenshot img {
  max-width: 300px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 13px 25px rgba(0, 0, 0, 0.05),
    0 60px 100px rgba(192, 192, 192, 0.5);
}
@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .app-screenshot img {
    display: table;
    position: static;
    -webkit-transform: translate(0);
    transform: translate(0);
    margin: 0 auto;
  }
}
.shards-app-promo-page--1 .feature p {
  font-size: 16px;
}
.shards-app-promo-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}
@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .feature .icon {
    margin-right: 1.5rem !important;
  }
}
.shards-app-promo-page--1 .blog .card-img-top {
  height: 100%;
}
.shards-app-promo-page--1 .section-invert {
  background: #f9fafc;
}
.shards-app-promo-page--1 .section-invert .card-title {
  font-weight: 700;
}
.shards-app-promo-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}
.shards-app-promo-page--1 .section {
  padding: 56px 44px;
}
.shards-app-promo-page--1 .section.bg-dark {
  background-image: linear-gradient(90deg, #1e1a2c 25%, #262136 25%, #262136 50%, #1e1a2c 50%, #1e1a2c 75%, #262136 75%, #262136 100%);
  background-size: 80.00px 80.00px;
  color: #fff;
}
.shards-app-promo-page--1 .section.bg-dark a {
  color: #fff;
}
.shards-app-promo-page--1 .section.bg-dark a:hover {
  color: rgba(255,0,66,1);
}
.shards-app-promo-page--1 .testimonials.section.bg-dark .section-title {
  color: #fff;
}

@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .subscribe button,
  .shards-app-promo-page--1 .subscribe input {
    width: 100%;
  }
}
/*# sourceMappingURL=shards-extras.min.css.map */

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

button.ant-modal-close{
  width:55px;
}
.ant-modal-close:hover {

  background: #d2c4be;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.7);
  font-size: 14px;
  font-weight: 600;
  text-transform: lowercase;
  margin: 0 8px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 4px 16px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255,255,255,1);
  border: 1px solid rgba(255,255,255,.7)  ;
}
.text-muted {
  text-transform: lowercase;
}
.text-centered {
  text-align: center;
}
.bg-dark {
    background-color: #222136!important
}
footer .navbar-dark .navbar-brand {
  text-transform: lowercase;
}
.navbar.navbar-expand-md.navbar-dark {
  margin: 0px;
  background: transparent;
}
@media (min-width: 768px) {
  .navbar.navbar-expand-md.navbar-dark {
    margin: 0px 48px 96px;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    text-align: center;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    margin-bottom: 32px;
  }
  .shards-app-promo-page--1 .section.beneficios img {
    margin-top: -56px;
    margin-bottom: -56px;
    max-width: 108%;
  }
}
.app-features.section img{
  height: 100vh;
}
@media (max-width: 768px) {
  .navbar.navbar-expand-md.navbar-dark {
    margin-bottom: 12px;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding: 16px;
    font-size: 16px;
    background: #222136;
    margin-bottom: 1px;
    opacity: .8;
  }
  .shards-app-promo-page--1 .feature p {
    margin: 0;
  }
  .shards-app-promo-page--1 .section {
    padding: 44px 20px;
  }
  .shards-app-promo-page--1 .section.welcome {
    min-height: auto;
    height: auto;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }
  .shards-app-promo-page--1 .section h5 {
    font-size: 18px;
  }
  .shards-app-promo-page--1 .section.subscribe p.t-1{
    margin-top: 24px;
  }
  .shards-app-promo-page--1 .section.beneficios img {
    width: 122%;
    margin-bottom: -44px;
    left: -10%;
    margin-right: -10%;
    position: relative;
  } 
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins";
  font-weight: 700;
}

p {
  line-height: 1.5em;
}
p.t-2 {
  font-size: 16px;
}

a.link {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, rgba(255,0,66,1), rgba(233,127,0,1));
  padding: 2px 16px;
  color: #222;
}
a.link:hover {
  border-image-source: linear-gradient(to right, rgba(255,0,66,1), rgba(233,127,0,1));
}
.shards-app-promo-page--1 .section.beneficios {
  padding: 0;
}
.features-bg {
  background-image: url("/imagesHome/app-promo/Mi_Hosting.png");
  background-position: left;
  background-size: cover;
  position: static;
  position: initial;
}
.main-navbar .navbar .user-avatar {
  max-width: 2rem;
  
}
#profile .nav-link {
  font-size: 0.6525rem;
  margin-left: 5px;
}


.main-navbar .navbar .navbar-nav .dropdown-menu{

  width:auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #3d5170;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  box-shadow: inset 0.1875rem 0 0 #007bff;
  background-color: #FBFBFB;
  color: #007bff;
}
.main-navbar .navbar .nav-link {
  padding: 0 1.2rem;
  margin: 0;
  display: flex;
  align-items: center;
  height: 60px;
}
.nav.mobile #logo-small {
  height: 32px;
  margin: 0 8px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.main-sidebar .nav .ant-collapse .nav-item:nth-child(3) .nav-link {
  background: red;
  color:#fff;
}
.main-sidebar .nav .nav-item.nav-item:nth-child(3) .nav-link i.material-icons{
  color: #fff;
}
.main-sidebar .nav .nav-item.nav-item:nth-child(3) .nav-link:hover i.material-icons,.main-sidebar .nav .nav-item.nav-item:nth-child(3) .nav-link.active i.material-icons{
  color: red;
}

.App {
  text-align: center;
}
.paddingsButtons{
  padding-bottom: 40px;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  max-width: 50px;
}
.itemDash {
  max-width: 14%;
  display: inline-block;
  margin: 5px 0px 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center !important;
  width: 100%;
  font-weight: bold;
}

.itemFB {
  border: 1px solid rgb(2, 151, 196);
  color: rgb(2, 151, 196);
  border-radius: 5px 0px 0px 5px;
}

.itemOLX {
  border: 1px solid rgb(152, 1, 152);
  color: rgb(152, 1, 152);
}

.itemAC {
  border: 1px solid rgb(15, 149, 23);
  color: rgb(15, 149, 23);
}

.itemDM {
  border: 1px solid rgb(254, 152, 1);
  color: rgb(254, 152, 1);
}

.itemML {
  border: 1px solid rgb(219, 56, 17);
  color: rgb(219, 56, 17);
}

.itemIG {
  border: 1px solid rgb(219, 203, 16);
  color: rgb(219, 203, 16);
}

.itemNA {
  border: 1px solid rgb(50, 101, 202);
  color: rgb(50, 101, 202);
  border-radius: 0px 5px 5px 0px;
}
.itemLV {
  border: 1px solid rgb(254, 152, 1);
  color: rgb(254, 152, 1); 
  border-radius: 0px 5px 5px 0px; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-collapse-content-box {
  padding: 0px !important;
  border-top: 0px !important;
}
.App-link {
  color: #61dafb;
}

@media print {
  .noprinter {
    display: none !important;
  }

  .noprintercs {
    margin-left: 0px !important;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  background: #fff;
  opacity: 0.4;
  position: fixed;
}
.ant-spin-blur::after {
  background: #fff;
  opacity: 0.4;
}

.ant-table-pagination {
  float: none !important;
}

.ant-pagination {
  text-align: center;
  padding-bottom: 6px;
}
.carta_vinculacion {
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -7px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .cardealertext {
    max-width: 105px;
  }
  .cardealertext2 {
    white-space: pre-wrap;
  }
}
/* Layout */
main {
  background: #f1f1f1;
}

.btn,
.ant-btn {
  font-weight: 400;
  font-family: "Poppins";
  border: 1px solid transparent;
  padding: 0.85rem;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 0.25rem;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  height: auto;
  color: #f1f1f1;
  white-space: unset;
}
.btn,
.ant-btn,
.btn-secondary {
  border: none;
  background: #ccc;
  background: linear-gradient(
    124deg,
    rgba(111, 111, 111, 1) 0%,
    rgba(122, 122, 122, 1) 100%
  );
}
.btn:hover,
.ant-btn:hover,
.btn-secondary:hover {
  color: #f1f1f1;
  border: none;
  background: #ccc;
  background: linear-gradient(
    124deg,
    rgba(122, 122, 122, 1) 0%,
    rgba(111, 111, 111, 1) 100%
  );
}
.btn.btn-primary,
.ant-btn.ant-btn-primary {
  border: none;
  background: rgb(255, 0, 66);
  background: linear-gradient(
    124deg,
    rgba(255, 0, 66, 1) 0%,
    rgba(233, 127, 0, 1) 100%
  );
}
.btn.btn-primary:hover,
.ant-btn.ant-btn-primary:hover,
.bg-primary,
.btn.btn-primary:active,
.ant-btn.ant-btn-primary:active,
.badge-primary {
  background: linear-gradient(
    124deg,
    rgba(233, 127, 0, 1) 0%,
    rgba(255, 0, 66, 1) 100%
  );
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #3d5170;
}
a {
  color: rgba(255, 0, 66, 1);
  font-weight: 700;
}
a:hover {
  color: #e97f00;
  font-weight: 700;
}
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 rgb(255, 0, 66);
  background-color: #fbfbfb;
  color: rgba(255, 0, 66, 1);
}
.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item:hover i {
  color: rgba(255, 0, 66, 1);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  box-shadow: inset 0.1875rem 0 0 rgba(255, 0, 66, 1);
  background-color: #fbfbfb;
  color: rgba(255, 0, 66, 1);
}
h1,
h2,
h3,
h4,
h5,
. {
  font-family: "Poppins";
  color: #212c3d;
  font-weight: 700;
}
.ant-modal-root .ant-modal-content .ant-modal-title {
  font-family: "Poppins";
  color: #212c3d;
  font-weight: 700;
  font-size: 22px;
}
.ant-input {
  height: 44px;
}
.ant-form-item {
  margin-bottom: 12px;
}

/* Common Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #3d5170;
  font-weight: 700;
  line-height: 1;
}
.t-1 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.8em;
}
.t-2 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  color: #3d5170;
}
.t-3 {
  color: #333;
  font-weight: normal;
  font-weight: initial;
  font-size: 0.875em;
  line-height: 1.2em;
}
.t-4 {
  font-size: 0.75em;
}
.fw-400 {
  font-weight: 400;
}
.c-blue {
  color: #3d5170;
}
.react-card-flipper,
.react-card-flip {
  background: transparent;
  border-radius: 5px;
  box-shadow: none;
}
.card {
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 2px 4px rgba(90, 97, 105, 0.12),
    0 5px 5px rgba(90, 97, 105, 0.06), 0 3px 35px rgba(90, 97, 105, 0.1);
  background: #fff;
}
.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  font-weight: 600;
  margin: 0 !important;
}

.page-header .page-title {
  font-weight: 700;
}
.navbar-expand-md .navbar-collapse {
  justify-content: center;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse {
  border-radius: 0;
}
.card {
  border-radius: 5px;
  overflow: hidden;
}
.card-img {
  border-radius: unset;
}
.card-detail {
  min-height: 150px;
}
.ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
/* User Menu */
.dropdown-menu-small .dropdown-item:hover,
.dropdown-menu-small .dropdown-item,
.main-navbar .navbar .nav-link {
  font-weight: 400;
  color: #3d5170;
}
.dropdown-menu-small .dropdown-item:hover,
.main-navbar .navbar .nav-link:hover {
  color: rgba(255, 0, 66, 1);
}
.shards-app-promo-page--1 .testimonials .testimonial .avatar {
  max-width: 80px;
  max-height: 80px;
}
.shards-app-promo-page--1 .testimonials .testimonial .avatar img {
  width: 100%;
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .page-header .page-title {
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 16px;
  }
}

.z_index_min {
  z-index: 92099 !important;
}

.z_index_max {
  z-index: 93000 !important;
}

.ant-select-dropdown {
  z-index: 93001 !important;
}

/* NavBar test */
.main-sidebar {
  background: #fafafa;
}
.ant-collapse {
  background: transparent;
  color: #3d5170;
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border: none;
}
.main-sidebar .nav .nav-item .nav-link,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #3d5170;
  background: transparent;
  font-weight: 500;
}
.main-sidebar .nav .nav-item .nav-link,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.9375rem 1.5625rem;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 26px;
  left: unset;
}
.main-sidebar .nav .nav-item .nav-link i {
  color: #3d5170;
}
.ant-collapse-content {
  border-top: none;
}
.ant-collapse
  > .ant-collapse-item
  .ant-collapse-content-box
  .nav-item
  .nav-link {
  font-weight: 400;
  font-size: 14px;
}

@media (min-width: 991.98px) {
  .main-sidebar .nav .nav-item .nav-link i.material-icons {
    font-size: 1.3rem;
  }
}

@media (max-width: 991.98px) {
  .main-sidebar .navbar-brand {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .main-sidebar .nav .nav-item .nav-link,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 22px;
    font-weight: 400;
  }
  .main-sidebar .nav .nav-item .nav-link i {
    font-size: 1.5rem;
  }
  .ant-collapse
    > .ant-collapse-item
    .ant-collapse-content-box
    .nav-item
    .nav-link {
    font-size: 16px;
  }
}
.main-sidebar .navbar-brand {
  font-size: 1.35rem;
}

.ant-dropdown-placement-bottomRight {
  z-index: 1070;
}

.card_message_1 {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: 15px 0;
  word-break: break-all;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  direction: ltr;
}

.chat_wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
}

.chabox {
  width: 100%;
    max-width: 800px;
    height: calc(60vh - 50px);
    min-height: 100%;
    padding: 15px 30px;
    margin: 0 auto;
    /*overflow-y: scroll;*/
    background-color: #fff;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    direction: rtl;
}

.mj-100 {
  width: 100%;
    align-items: center;
    justify-content: center;
}
.circuloEN {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     background: #5cb85c;
     border: 1px solid gray;
}

.bg-leido {
  background: #eee;
}
.circuloDI {
  width: 30px;
  height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid gray;
  border-radius: 50%;
  background: #8f0c0c;
}


.card_message_2 {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: 15px 0;
  word-break: break-all;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  direction: ltr;
  color: #fff;
  margin-left: auto;
  background-color: #00a9de;
}


* {
  /* overflow-x:hidden;*/
}
.tops {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 80px;

  background-color: #032445;
}

.sin-bottom {
  margin-bottom: 0px !important;
}

@media (maxWidth: 767px) {
  .desktop-only {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

.icon-body {
  width: 100px;
  padding: 15px;
  border-radius: 60%;
  color: white;
  margin: 0 auto;
  box-shadow: 0px 10px 50px -18px rgba(0, 0, 0, 0.75);
  margin-bottom: 15px;
  margin-top: 20px;
}
.icon-body-white {
  width: 90px;
  height: 90px;
  padding: 15px;
  border-radius: 60%;
  border: 5px solid white;
  color: white;
  margin: 0 auto;
  margin-bottom: 15px;
}

.margin-doble {
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 60px !important;
}

.inline-form {
  display: inline !important;
}

.texto_verde {
  color: #17c671 !important;
}
.icon-color-rose {
  background: #f56eab;
}

.icon-color-green {
  background: #7cc576;
}

.icon-color-blue {
  background: #49b5e7;
}

.side {
  padding: 110px 0;
}

.logo-cca-img {
  maxWidth: 200px;
  width: 100%;
}

.letra {
  padding-top: 20px;
  color: #ff9547;
}

.texto {
  padding-top: 10px;
  color: #888888;
}

.pantalla1 {
  width: 100%;
  height: auto;
}

.container-sin-derecha {
  margin-right: 0px;
  padding-right: 0px !important;
}

.container-con-padding-top {
  padding-top: 80px;
}

.centerized {
  text-align: center;
}

.iconos {
  width: 4rem !important;
  height: 4rem !important;
}

.iconos-con {
  width: 3rem !important;
  height: 3rem !important;
}

.nav-icon {
  width: 2rem !important;
  height: 2rem !important;
  color: white;
}

.centrar-verticalmente {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
}

.social_img {
  width: 100%;
}

.gray_bar {
  background-color: #484a47;
  color: #eae7e7;
  font-size: 26px;
  padding: 40px 0;
}

.pk {
  line-height: 33px;
}

.naranja {
  color: #ff9547;
}

.footer {
  background: url("/img/background.gif");
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}

.texto_blanco {
  color: white;
}

.fixed_navbar {
  position: fixed !important;
  width: 100%;
  z-index: 10000;
  background: #032445 !important;
}

.border-white {
  border: 1px solid white;
}

.border-verde {
  border: 1px solid #17c671 !important;
}

.c-blanco {
  color: white !important;
}
.collapsible {
  z-index: 50;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  transition: all 0.5s;
  width: 600px;
  position: absolute;

  right: -590px;
  opacity: 0;
}

.collapsible.open {
  right: 100px;
  opacity: 1;
}

#nav-icon1 {
  cursor: pointer;
  width: 34px;
  height: 40px;
  position: relative;
  margin: 10px auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 6px;
}

#nav-icon1 span:nth-child(3) {
  top: 12px;
}

#nav-icon1.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1 1;
  maxWidth: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (maxWidth: 575px) {
  .date-range .react-datepicker-wrapper {
    maxWidth: 100%;
  }
}


.trr {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
} 

table {
    width:100%
}  

.down {
    color: #db3811 !important;
}

.up {
    color: #109618 !important;
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
}

.padding_flex {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.close {
  width: 60px !important;
  float: right;
}

.close:hover{
  background: transparent;
}

[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow1"] {
    width: 12px;
    height: 12px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 5px;
  }
  [class*="icono-arrow1"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 23px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow1"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow1"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow1"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow1"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow1"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow1"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow2"] {
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin: 10px;
  }
  [class*="icono-arrow2"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 15px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow2"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow2"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow2"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow2"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow2"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow2"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow3"] {
    width: 18px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    border-radius: 0 2px 0 0;
  }
  [class*="icono-arrow3"]:before,
  [class*="icono-arrow3"]:after {
    border-radius: 2px;
    position: absolute;
  }
  [class*="icono-arrow3"]:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  [class*="icono-arrow3"]:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
  [class*="icono-arrow3"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow3"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow3"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow3"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow3"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow3"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow4"] {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 7px solid;
    border-right: 7px solid;
    margin: 15px;
  }
  [class*="icono-arrow4"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow4"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow4"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow4"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow4"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow4"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  
.fontConcecionaria{
    font-size: 12px;
    color:#3d5170;
}
.fontInfoCar{
    font-size: 21px;
    font-weight: 700;
    color:#3d5170;
}
.fontPriceCar{
    font-size: 19px;
    font-weight: 500;
    background: #ff0042;
    background: linear-gradient(
        124deg,
        rgba(255, 0, 66, 1) 0%,
        rgba(233, 127, 0, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .fontPriceCarToPrint{
        color:#ff0042;
        font-size: 19px;
        font-weight: 500;
}
.fontLocationCar{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color:#3d5170;
}
.fontTextSecondary{
    font-family: roboto;
    font-size: 13px;
    color:#3d5170;
    font-weight: 400;
}
.fontCreateData{
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: 0;
    color:#3d5170;
    top: 0;

}
.fontDealerInfo{
    font-size: 19px;
    font-weight: 700;
    color:#3d5170;
}
.fontDealerInfoHorus{
    font-size: 18px;
    color:#3d5170;
    font-weight: 400;
}
.fontDealerInfoHorusDays{
    font-size: 13px;
    font-weight: 400;
    color:#3d5170;
}
.containerInfoCarSectionDetail{
    position: relative;
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
}
.containerInfoDealerSectionDetail{
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
    
}
.button-primary-info-dealer{
    width: 100%;
    height: 63px;
    border-radius: 7px;
    font-size: 19px;
    font-weight: bold;
}


@media (max-width: 575.98px) {
    .containerInfoCarSectionDetail{
        width: 100%;
    }
    .infoDealerSectionDetail{
        margin-top: 25px;
        width: 100%;
    }

}

.modalShow{
    display: none;
}
.modalQrToPrint{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 100px;
}
.ModalQrRender{
    padding: 20px;
}
.QrGenerate{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ButtonToPrintQR{
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width: 575.98px) {
    .containerQr {
        display: flex !important;
        width: 100%;
        flex-direction: column !important;
        justify-content:center ;
        align-items: center;
    }
    .QrGenerate{
        width: 100%;
    }
    .infoDealerQrContainer{
        margin-top: 20px;
        width: 100%;
    }
    .ant-modal-content{
    margin-top: 75px !important;
    }
}
.slider-checkbox {
  position: relative;
}
.slider-checkbox input {
  margin: 0px;
  margin-top: 1px;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: red;
  width: 40px;
  height: 20px;
}
.slider-checkbox input:checked + .label:before {
  background-color: #1877c1;
  content: "\f00c";
  padding-left: 6px;
}
.slider-checkbox input:checked + .label:after {
  left: 21px;
}
.slider-checkbox .label {
  position: relative;
  padding-left: 46px;
}
.slider-checkbox .label:before, .slider-checkbox .label:after {
  position: absolute;
  border-radius: 10px;
  transition: background-color 0.3s, left 0.3s;
}
.slider-checkbox .label:before {
  content: "\f00d";
  color: #fff;
  box-sizing: border-box;
  font-family: 'FontAwesome', sans-serif;
  padding-left: 23px;
  font-size: 0px;
  line-height: 20px;
  background-color: #888;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 40px;
  border-radius: 10px;
}
.slider-checkbox .label:after {
  content: "";
  letter-spacing: 20px;
  background: #fff;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px;
}
#wrapper {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 24px;
  width: 720px;
  margin: 12px auto;
  background: #fff;
}
p.center {
  text-align: center;
}
.slider-checkbox {
  margin-top: 12px;
  margin-bottom: 12px;
}
.slider-checkbox input:checked + .label[for="c1"]:before, .slider-checkbox input:not(:checked) + .label[for="c1"]:before {
  background-color: #c21717;
}
.slider-checkbox input:checked + .label[for="c2"]:before {
  background-color: #ffdc00;
}
.slider-checkbox input:not(:checked) + .label[for="c3"]:before {
  background-color: #0a4776;
}

.red-snow {
  color: #c21717;
}

.modal-backdrop {
  background: #000;
}

.modal-backdrop.show {
  opacity: .8;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background: rgb(255,0,66);
}
.dropdown-menu {
  right: 15px;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.right_bottom_po {
  float: right !important;

  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border: 1px solid transparent;
}

.left_bottom_po {
  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border: 1px solid transparent;
}
.dropdown-menu {
  padding: 0;
}
.react-card-flip {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
  border-radius: .625rem;
  background: rgba(90,97,105,.1);
  margin: 0px !important;
}

.ant-row {
  display: flex;
  flex-wrap: wrap;
}
.editorClassName {
    border: 2px solid black !important;
}

.content-inputCustomFile {
  padding: 20px;
  border: 1px dashed rgb(154, 154, 154);
}
.inputCustomFile {
  height: 60px;
  width: 100%;
  display: flex;
  border: 1px dashed #d9d9d9;
  margin: 0px 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  background: #f9f2f35e;
}
.left {
  float: left;
}
.Icon {
  opacity: 0.3;
  height: 84px;
  width: 84px;
}

.FileInput {
  display: none;
}

/* Images */
.file-list {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  margin: 10px;
  cursor: move;
  transition: all 0.2s linear;
}
.ant-upload-list-item,
.ant-upload-list-item-actions .anticon-delete {
  margin: 0 !important;
  padding: 0 !important;
  border: none 0 !important;
  font-size: 20px !important;
  color: #f39b19 !important;
}

/* Tablets */
@media (max-width: 1000px) {
}

/* Mobiles */
@media (max-width: 640px) {
  .inputCustomFile {
    font-size: 11px;
  }
  .file-item {
    width: 50%;
    height: 150px;
    float: left;
    max-height: 250px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 100%;
    height: 150px;
  }
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.right_bottom_po {
  float: right !important;

  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  border: 1px solid transparent;
}

.left_bottom_po {
  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  border: 1px solid transparent;
}
.dropdown-menu {
  padding: 0;
}
.react-card-flip {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 0.625rem;
  background: rgba(90, 97, 105, 0.1);
  margin: 0px !important;
}

.colPublish {
  text-align: center;
  padding: 25px;
}

.csshide {
  opacity: 0.3;

  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.cssshow {
  visibility: visible;
  opacity: 1;

  transition-delay: 0s;
}

.cssback {
  background: #aeb1b71c;
}
.pontier {
  cursor: pointer;
  text-decoration: underline;
}

.hide {
  display: none;
}
.textValidation {
  color: red;
  font-size: 10px;
}
.textAviso {
  color: #f16717;
  font-size: 10px;
}


.ant-upload-select {
    width: 100% !important;
}


.trr {
    margin-top: 12px;
    margin-bottom: 12px !important;
} 

table {
    width:100%
}  

.down {
    color: #db3811 !important;
}

.up {
    color: #109618 !important;
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
    margin-top: -6px !important;
}

.padding_flex {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow1"] {
    width: 12px;
    height: 12px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 5px;
  }
  [class*="icono-arrow1"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 23px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow1"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow1"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow1"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow1"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow1"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow1"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow2"] {
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin: 10px;
  }
  [class*="icono-arrow2"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 15px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow2"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow2"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow2"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow2"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow2"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow2"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow3"] {
    width: 18px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    border-radius: 0 2px 0 0;
  }
  [class*="icono-arrow3"]:before,
  [class*="icono-arrow3"]:after {
    border-radius: 2px;
    position: absolute;
  }
  [class*="icono-arrow3"]:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  [class*="icono-arrow3"]:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
  [class*="icono-arrow3"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow3"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow3"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow3"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow3"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow3"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow4"] {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 7px solid;
    border-right: 7px solid;
    margin: 15px;
  }
  [class*="icono-arrow4"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow4"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow4"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow4"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow4"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow4"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  
  




.image-gallery img {
    width: 100% !important;
    height: 65px !important;
  }
  
  .image-gallery-slide img {
    width: 100% !important;
    height: 550px !important;
    max-height: 80vh !important;
    object-fit: cover !important;
    overflow: hidden !important;
    object-position: center center !important;
  }
  
  .fullscreen .image-gallery-slide img {
    width: 100% !important;
    height: 90% !important;
    object-fit: cover !important;
    object-position: center center !important;
    max-height: 90vh !important;
  }
  .fullscreen {
    background: white !important;
    width: 100% !important;
    height: 100vh !important;
  }
 
  
@media (min-width: 320px) and (max-width: 767px) {
  .sliderImageSectionDetail{
    width: 100%;
  }
  }
.fontTextDescription{
    font-family: roboto;
    font-size: 17px;
    font-weight: 400;
}
.containerInfoDealerSectionDetail{
    position: relative;
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
}
.containerInfoDealerSectionDetail h6{
    font-family: poppins;
}
.imagesOtrosSitios{
    width: 50px;
    border-radius: 50%;
    margin-left: 10px;
}

/* ACORDENES DE DATOS DEL AUTO */
.collapse-container-custom {
    display : flex;
  }
  
  .collapse-container-custom > .ant-collapse-item {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .collapse-panel-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width : 100%;
}

.collapse-panel-custom > .ant-collapse-header {
    width: 100%;
}

.collapse-panel-custom > .ant-collapse-content {
    width: 100%;
    padding: 20px;
  }

.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
    color : black;
    box-shadow: none; 
}


@media (min-width: 320px) and (max-width: 767px) {
    .otrosSitiosContainer{
        display: none;
    }
    .descriptionContainerSectionDetail{
        width: 100%;
        text-align: justify;
    }
    .collapse-container-custom{
        display: flex;
        flex-direction: column;
    }
    .ant-collapse-header{
        margin: 10px 12px;
    }
    }

.OtherPublicationDealer h5{
    text-align: center;
}

.ant-upload-select {
    width: 100% !important;
}


.trr {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
} 

table {
    width:100%
}  

.down {
    color: #db3811 !important;
}

.up {
    color: #109618 !important;
    -webkit-transform: rotate(-45deg) !important;
            transform: rotate(-45deg) !important;
}

.padding_flex {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow1"] {
    width: 12px;
    height: 12px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 5px;
  }
  [class*="icono-arrow1"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 23px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow1"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow1"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow1"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow1"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow1"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow1"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow2"] {
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin: 10px;
  }
  [class*="icono-arrow2"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    width: 15px;
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
  [class*="icono-arrow2"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow2"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow2"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow2"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow2"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow2"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow3"] {
    width: 18px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    border-radius: 0 2px 0 0;
  }
  [class*="icono-arrow3"]:before,
  [class*="icono-arrow3"]:after {
    border-radius: 2px;
    position: absolute;
  }
  [class*="icono-arrow3"]:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: right top;
            transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  [class*="icono-arrow3"]:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
  [class*="icono-arrow3"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow3"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow3"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow3"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow3"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow3"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  [class*="icono-arrow4"] {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 7px solid;
    border-right: 7px solid;
    margin: 15px;
  }
  [class*="icono-arrow4"][class*="-left"] {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  [class*="icono-arrow4"][class*="-left"][class*="-up"] {
    -webkit-transform: none;
            transform: none;
  }
  [class*="icono-arrow4"][class*="-left"][class*="-down"] {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [class*="icono-arrow4"][class*="-right"] {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-up"] {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-down"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  [class*="icono-arrow4"][class*="-up"] {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  [class*="icono-arrow4"][class*="-down"] {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  
.fullheight {
  height: 90vh !important;
  border: 0 none;
  display: inline-block;
  margin: 0 auto;
}

.data-row{
    background: linear-gradient(
        124deg,
        rgba(255, 0, 68, 0.4) 0%,
        rgba(233, 128, 0, 0.4) 100%
      );
}
.data-row2{
    background: #f0f0f0
}



